import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tags from "../components/tags"
import { IndexHeader } from "../components/header"
import { pathParts, breadcrumbPathGenerator } from "../util/helpers"

const Category = ({ data, path }) => {
  const { edges } = data.allMarkdownRemark

  const parts = pathParts(path)
  const category = parts.pop()

  return (
    <Layout>
      <SEO
        title={category}
        description={`everything you want to know about ${category}`}
        path={path}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader breadcrumbs={breadcrumbPathGenerator(parts)}>
          {category}
        </IndexHeader>
        <ul className="divide-gray-300 divide-y">
          {edges
            .filter(({ node }) => {
              const rawDate = node.frontmatter.rawDate
              const date = new Date(rawDate)
              return date < new Date()
            })
            .map(({ node }) => {
              const { readingTime } = node.fields
              const { title, date, path, tags } = node.frontmatter
              return (
                <li key={node.id} className="pb-10">
                  <Link to={path} className="no-underline group">
                    <div className="mb-3">
                      <div className="mb-1">
                        <h3 className="font-extrabold group-hover:text-highlight-yellow inline leading-tight text-3xl text-primary-500">
                          {title}
                        </h3>
                      </div>

                      <div>
                        <h5 className="inline text-gray-600">{date}</h5>
                        <h5 className="inline text-gray-600">
                          - {readingTime.text}
                        </h5>
                      </div>
                    </div>
                    <p className="text-gray-700">{node.excerpt}</p>
                  </Link>
                  <div className="mt-3 text-xs flex items-center">
                    <span className="text-gray-500 mr-3">Tags:</span>
                    <Tags tags={tags} />
                  </div>
                </li>
              )
            })}
        </ul>
      </section>
    </Layout>
  )
}

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Category

export const categoryPageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { category: { eq: $category }, draft: { eq: false } }
      }
    ) {
      edges {
        node {
          id
          parent {
            internal {
              type
              mediaType
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
            tags {
              tagId
              tag
            }
          }
          excerpt
        }
      }
    }
  }
`
